/* .tox .tox-edit-area__iframe {
	height: calc(100vh - 335px) !important;
}

.tox .tox-tinymce .tox-tinymce--toolbar-sticky-off {
	height: auto !important;
}
.mceEditor table {
	height: auto !important;
} */

.section img {
	@media screen and (max-width: 1000px) {
		width: 100% !important;
	}
}
